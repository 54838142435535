<script setup lang="ts">
import Generic from '~/layouts/generic.vue'
import Header from '~/components/Layout/Header.vue'
import Banner from '~/components/Layout/Banner.vue'
import { useBannerStore } from '~/stores/useBannerStore';

const { isBannerVisible } = useBannerStore()
const { loadBrandList } = useBrandStore()
const { setCategory } = useCategoryStore()

useAsyncData('category', async () => setCategory())
useAsyncData('brand-list', async () => loadBrandList())
</script>

<template>
  <Generic>
    <Header />
    <Banner v-if="isBannerVisible" />
    <Toast position="bottom-center" group="bc" />
    <div :class="`${isBannerVisible ? 'mt-28' : 'mt-20'} w-full bg-white ${isBannerVisible ? 'md:mt-28' : 'md:mt-28'}`">
      <router-view />
    </div>
  </Generic>
</template>